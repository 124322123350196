/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* i.e. 1rem */
}

body {
  font-family: Lato, "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cloud-rendering-config {
  height: 100vh;
  margin:20px;
}

.cloud-rendering-preview  {
  height: 100vh;
}

.cloud-rendering-preview {
  background-color: #F8F8F8;
  padding: 15vw;
}

.action-buttons {
  padding: 15px 0 0 0;
}

h3 {
  padding: 15px 0 0 0;
}

.footer {
  padding-top: 15%;
  align: bottom;
}

.error-message {
  padding-top: 15px;
  color: #ff6961;
}

.config-panel {
  margin: auto;
  width: 25vw;
}

.login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.credential-details {
  padding-top: 15px;
  padding-bottom: 15px;
}

.login-card {
  width: 40vw !important;
}

.login-card.error {
  border: 1px solid red !important;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  @media (min-width: 1600px) {
    max-width: 85%;
  }
}

.inner {
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border: 1px solid #ccc;
  position: relative; /* Contents will be absolute inside this, due to the padding + 0 height. */
}

.contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #6D7278;
  border: 10px solid black;
  background-size: cover;
  box-shadow: 0px 20px 25px -4px rgba(0, 0, 0, 0.75);
  
}

.contents p {
  margin: 0;
  font-size: larger;
  position: absolute;
  top: 45%;
  left: 43%;
  color: #F8F8F8;
}

.elipsis-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1000px) {
  .cloud-rendering-preview {
    display: none;
  }
}

.error-message {
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  color: red;
}

